export const filters = [
  {
    id: 'event_types',
    type: 'checkbox-filter',
    title: 'Тип события',
    prop: [
      { id: 'Перекрытие', name: 'Перекрытие' },
      { id: 'КМ ДТП', name: 'КМ ДТП' },
      { id: 'Осмотры ТС', name: 'Осмотры ТС' },
      { id: 'Профилактические мероприятия', name: 'Профилактические мероприятия' },
      { id: 'Окончание пропуска ТС', name: 'Окончание пропуска ТС' },
      { id: 'КМ нарушения', name: 'КМ нарушения' },
      { id: 'Знаки', name: 'Знаки' }
    ],
    active: false
  }
]
